var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-select", {
        staticClass: "input-max-width",
        attrs: {
          dense: "",
          "hide-details": "",
          items: _vm.computedFilterOptions,
          outlined: "",
          label: "Filter by File Type",
          loading: _vm.loading,
          color: "primary"
        },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function(ref) {
              var item = ref.item
              return [
                item.color
                  ? _c(
                      "v-chip",
                      {
                        attrs: { color: item.color, small: "", dark: item.dark }
                      },
                      [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]
                    )
                  : _c("span", [
                      _vm._v("\n        " + _vm._s(item.text) + "\n      ")
                    ])
              ]
            }
          },
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-row",
                  { attrs: { align: "center" } },
                  [
                    item.color
                      ? _c(
                          "v-col",
                          { attrs: { cols: "2" } },
                          [
                            _c(
                              "v-chip",
                              { attrs: { color: item.color, dark: item.dark } },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.name) +
                                    "\n          "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("v-col", { attrs: { cols: item.color ? "10" : "12" } }, [
                      _c("span", [_vm._v(" " + _vm._s(item.text))])
                    ])
                  ],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.filter,
          callback: function($$v) {
            _vm.filter = $$v
          },
          expression: "filter"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }