<template>
  <div>
    <v-select
      v-model="filter"
      dense
      hide-details
      :items="computedFilterOptions"
      outlined
      label="Filter by File Type"
      :loading="loading"
      class="input-max-width"
      color="primary"
    >
      <template v-slot:selection="{ item }">
        <v-chip
          v-if="item.color"
          :color="item.color"
          small
          :dark="item.dark"
        >
          {{ item.name }}
        </v-chip>
        <span v-else>
          {{ item.text }}
        </span>
      </template>
      <template v-slot:item="{ item }">
        <v-row align="center">
          <v-col
            v-if="item.color"
            cols="2"
          >
            <v-chip
              :color="item.color"
              :dark="item.dark"
            >
              {{ item.name }}
            </v-chip>
          </v-col>
          <v-col
            :cols="item.color ? '10' : '12'"
          >
            <span> {{ item.text }}</span>
          </v-col>
        </v-row>
      </template>
    </v-select>
  </div>
</template>

<script>
import { nowwService } from '@/shared/services'
import mutationTypes from '@/store/mutation-types'
import { dashboardMixin } from '@/shared/mixins/mailing'

export default {
  mixins: [dashboardMixin],

  data: function () {
    return {
      loading: false,
      filterOptions: [{ text: 'All File Types', value: 0 }],
      fileTypeOptions: []
      // unsubscribe: null
    }
  },

  computed: {
    filter: {
      get () {
        return this.$store.getters.fileTypeFilter()
      },
      set (value) {
        this.$store.dispatch('setFileTypeFilter', value)
      }
    },
    computedFilterOptions () {
      return [...this.filterOptions, ...this.fileTypeOptions]
    }
  },

  created () {
    this.loadFileTypes(
      this.$store.getters['auth/getTenantCrmId']()
    )
  },

  mounted () {
    // this.unsubscribe = this.$store.subscribe(mutation => {
    //   let listeners = [
    //     mutationTypes.SET_VIEW_AS_CUSTOMER
    //   ]
    //   if (listeners.includes(mutation.type)) {
    //     this.loadFileTypes(this.$store.getters['simulatedCustomerCrmId']())
    //   }
    // })
  },

  destroyed () {
    // this.unsubscribe()
  },

  methods: {
    loadFileTypes (tenantId) {
      this.loading = true
      nowwService
        .getFileTypes(tenantId)
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
            this.fileTypeOptions = []
          } else {
            var context = this
            this.fileTypeOptions = resp.map(function (val, index) {
              return {
                text: val.description,
                value: val.fileTypeID,
                name: val.fileTypeName,
                color: context.$_dashMixin_fileTypeColor(val.fileTypeName),
                dark: context.$_dashMixin_fileTypeColorDark(val.fileTypeName)
              }
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
